import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { useCurrentUser } from 'hooks/auth';
import { usePushWithQuery, useSearchParams } from 'hooks/routing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadVariablesType } from 'screens/talent/job-board-v2/hooks';
import { TalentJobSourse } from 'screens/talent/job-board-v2/types';

import { Job, Query, TalentCompanyPoolConnection } from '@libs/graphql-types';

import { GET_JOBS } from './queries';
import { FilterForm, ViewTypes } from './types';
import {
  mapFormToQuery,
  mapQueryToInitialValues,
  mapQueryToVariables,
} from './utils';

export const useQueryParamsToLoadVariables = (
  currentView: ViewTypes,
): LoadVariablesType => {
  const params = useSearchParams();
  const variables = useMemo(() => {
    return {
      source: TalentJobSourse.Opentalent,
      variables: mapQueryToVariables(params, currentView),
    };
  }, [params, currentView]);

  return variables;
};

export const useJobList = (currentView: ViewTypes) => {
  const { variables } = useQueryParamsToLoadVariables(currentView);
  const [loadJobs, { data, loading }] = useLazyQuery<Query>(GET_JOBS, {
    variables,
    fetchPolicy: 'network-only',
  });

  const loadWithOptions = useCallback(() => {
    loadJobs({ variables });
  }, [loadJobs, variables]);

  useEffect(() => {
    loadWithOptions();
  }, [loadWithOptions, variables]);

  const { currentPage = 1, lastPage = 1 } =
    data?.currentTalentJobBoardSearch?.paginatorInfo || {};

  return {
    jobs: data?.currentTalentJobBoardSearch?.data || [],
    loadingJobList: loading,
    currentPage,
    lastPage,
    loadJobs: loadWithOptions,
  };
};

export const useJobModals = () => {
  const [inviteJob, setInviteJob] = useState<Required<Job>>();
  const [applyJob, setApplyJob] = useState<Required<Job>>();

  const handleClose = useCallback(() => {
    setInviteJob(undefined);
    setApplyJob(undefined);
  }, []);

  return {
    inviteJob,
    applyJob,
    handleClose,
    onJobApply: setApplyJob,
    onInvite: setInviteJob,
  };
};

export const useAutoSaveHandler = () => {
  const push = usePushWithQuery();
  return useCallback(
    (values: FilterForm) => push({ query: mapFormToQuery(values) }),
    [push],
  );
};

export const useInitialValues = () => {
  const params = useSearchParams();
  const { getData } = useCurrentUser();
  const connections = (
    (getData()?.data?.currentTalent
      ?.company_pool_connections as TalentCompanyPoolConnection[]) || []
  ).filter((i) => i.status === 'APPROVED');

  return mapQueryToInitialValues(params, { connections });
};

export const usePaginationChange = () => {
  const { setFieldValue } = useFormikContext();

  const onPaginationChange = useCallback(
    (e: React.ChangeEvent<unknown>, page: any) => {
      setFieldValue('page', page);
    },
    [setFieldValue],
  );

  return onPaginationChange;
};
