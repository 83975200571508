import { useCurrentUser } from 'hooks/auth';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';

import {
  useGetPaymentLinkForTalentLazyQuery,
  useGetPaymentLinkForRecruiterLazyQuery,
  Query,
  AccountTypeEnum,
  useSubmitTalentAsFreeMatcherMutation,
} from '@libs/graphql-types';

const QUERIES_MAP: Record<AccountTypeEnum, any> = {
  [AccountTypeEnum.Member]: useGetPaymentLinkForTalentLazyQuery,
  [AccountTypeEnum.Matcher]: useSubmitTalentAsFreeMatcherMutation,
  [AccountTypeEnum.MatcherComplementary]: useSubmitTalentAsFreeMatcherMutation,
  // [AccountTypeEnum.Matcher]: useGetPaymentLinkForMatcherLazyQuery,
  // [AccountTypeEnum.MatcherComplementary]: useGetPaymentLinkForMatcherLazyQuery,
  [AccountTypeEnum.Recruiter]: useGetPaymentLinkForRecruiterLazyQuery,
  [AccountTypeEnum.Free]: useGetPaymentLinkForTalentLazyQuery,
};

export const useSelectPremiumAction = ({
  type,
  talentId,
  isHrOnboarding,
}: {
  type: AccountTypeEnum;
  talentId?: string;
  isHrOnboarding?: boolean;
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useCurrentUser();
  const currentQuery = QUERIES_MAP[type];

  const [makeRequest, { loading }] = currentQuery({
    variables: {
      talent_id: user?.id || talentId,
      is_hr_onboarding: isHrOnboarding,
    },
    onCompleted: (res: any) => {
      const response = res as Query;

      if (
        type === AccountTypeEnum.Matcher ||
        type === AccountTypeEnum.MatcherComplementary
      ) {
        history.push(
          pathManager.talent.onboarding.recruiterFinish.generatePath(),
        );
        return;
      }

      const link: string | undefined =
        response?.getPaymentLinkForTalent ||
        response?.getPaymentLinkForMatcher ||
        response?.getPaymentLinkForRecruiter;
      if (!link) {
        enqueueSnackbar('Failed to get payment link', { variant: 'error' });
        return;
      }
      window.location.href = link;
    },
  });

  return { makeRequest, loading };
};
