import React from 'react';

import { AccountTypeEnum } from '@libs/graphql-types';
import Button, { ButtonProps } from '@libs/ui/components/button';

import { useSelectPremiumAction } from './hooks';

interface StripePaymentButtonProps
  extends React.PropsWithChildren<ButtonProps> {
  children: React.ReactNode;
  accountType: AccountTypeEnum;
  talentId?: string;
  isHrOnboarding?: boolean;
}

const StripePaymentButton = ({
  children = 'Select',
  accountType: type,
  talentId,
  isHrOnboarding,
  ...rest
}: StripePaymentButtonProps) => {
  const { makeRequest, loading } = useSelectPremiumAction({
    type,
    talentId,
    isHrOnboarding,
  });

  return (
    <Button
      size="large"
      rounded
      fullWidth
      variant="contained"
      color="info"
      disabled={loading}
      onClick={() => makeRequest()}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default StripePaymentButton;
