import {
  JobOrderByColumn,
  QueryCurrentTalentJobBoardSearchArgs,
  SortOrder,
  TalentCompanyPoolConnection,
} from '@libs/graphql-types';
import { isNil } from '@libs/helpers/common';
import { OptionType } from '@libs/ui/components/form/select';

import { FilterForm } from './types';

const mapOptionsToQuery = (opts: OptionType[]) =>
  opts.map(({ value, text, skill_type }) => {
    const arr = [value, text];
    return skill_type ? arr.concat(skill_type) : arr;
  });

export const mapFormToQuery = (form: FilterForm) => {
  return {
    ...form,
    externalJobSeniority: mapOptionsToQuery(form.externalJobSeniority || []),
    externalJobEmployment: mapOptionsToQuery(form.externalJobEmployment || []),
    externalCompanyName: mapOptionsToQuery(
      !!form.externalCompanyName ? [form.externalCompanyName] : [],
    ),
    categories: mapOptionsToQuery(form.categories || []),
    skills: mapOptionsToQuery(form.skills || []),
    companies: Object.entries(form.companies)
      .filter(([, v]) => v)
      .map(([k]) => k),
  };
};

const mapOptionsFromQuery = (str: string): OptionType[] =>
  str?.split(';').map((s) => {
    const [value = '', text = '', skill_type] = s?.split(',');
    return { value, text, ...(skill_type && { skill_type }) };
  });

export const mapQueryToVariables = (
  query: Record<string, any>,
  { isPaidTalentAccount }: { isPaidTalentAccount: boolean },
): QueryCurrentTalentJobBoardSearchArgs => {
  const values = mapQueryToInitialValues(query, { isPaidTalentAccount });

  return {
    search: values.search || undefined,
    categories: values.categories?.map((i) => `${i.value}`) || undefined,
    skills: values.skills?.map((i) => `${i.value}`) || undefined,
    rate_min: (values as any).rate_min || undefined,
    rate_max: (values as any).rate_max || undefined,
    is_saved: values.is_saved,
    country: values.country,
    is_active: values.is_active,
    communities_filter: {
      company_ids:
        Object.entries(values.companies || {})
          .filter(([, v]) => v)
          .map(([k]) => k) || [],

      include_external_jobs: !!values.jobSourceExternal,
    },
    page: Number(values.page) || 1,
    first: 20,
    ...(values?.order_by_column && {
      order_by: [
        {
          column: values.order_by_column as JobOrderByColumn,
          order: SortOrder.Asc,
        },
      ],
    }),
  };
};

export const mapQueryToInitialValues = (
  query: Record<string, any>,
  {
    isPaidTalentAccount,
    connections,
  }: {
    isPaidTalentAccount: boolean;
    connections?: TalentCompanyPoolConnection[];
  },
): Partial<FilterForm> => {
  const companies = isNil(query.companies) ? '' : String(query.companies);
  const defaultCompanies = Object.fromEntries(
    connections?.map((i) => [i.company?.id, true]) || [],
  );

  return {
    companies: !!companies
      ? Object.fromEntries(companies.split(';').map((i: string) => [i, true]))
      : defaultCompanies,
    externalJobSeniority: mapOptionsFromQuery(query?.externalJobSeniority),
    externalJobEmployment: mapOptionsFromQuery(query?.externalJobEmployment),
    externalCompanyName: !!query?.externalCompanyName
      ? mapOptionsFromQuery(query?.externalCompanyName)[0]
      : undefined,
    country: query?.country,

    categories: mapOptionsFromQuery(query?.categories),
    skills: mapOptionsFromQuery(query?.skills),
    search: query.search,
    rate_max: query.rate_max,
    rate_min: query.rate_min,
    order_by_column: query.order_by_column
      ? query.order_by_column
      : JobOrderByColumn.CampaignStart,
    is_saved: query.is_saved,
    page: query.page,
    is_active: query.is_active,

    jobSourceInternal:
      query.jobSourceInternal === undefined ? true : !!query.jobSourceInternal,

    jobSourceExternal:
      query.jobSourceExternal === undefined ? true : !!query.jobSourceExternal,
  };
};
